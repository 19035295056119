import React from "react";
import { Box, Container, Grid, Typography } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import CountUp from "react-countup";
const useCounterStyles = makeStyles((theme: Theme) =>
  createStyles({
    main: {
      position: "relative",
      "&::after": {
        position: "absolute",
        content: "''",
        backgroundColor: theme.palette.divider,
        height: 1,
        left: 0,
        right: 0,
        top: "50%",
        zIndex: -1,
        transform: "translate(0%,-50%)",
      },
    },
    root: {
      background: `linear-gradient(to right, #6AD2FC , #599BFC)`,
      borderRadius: 10,
      [theme.breakpoints.up("sm")]: {
        margin: theme.spacing(0, 6),
      },
    },
    label: {
      display: "block",
      width: "100%",
      [theme.breakpoints.down("sm")]: {
        fontSize: 18,
      },
      [theme.breakpoints.only("xs")]: {
        fontSize: 14,
      },
    },
    counter: {
      [theme.breakpoints.down("sm")]: {
        fontSize: 24,
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: 20,
      },
    },
    grid: {
      "&:not(:last-child)": {
        borderRight: "1px solid #ebebeb",
      },
    },
  })
);

const Counters: React.FC<CountersProps> = ({ content }) => {
  const classes = useCounterStyles();
  return (
    <div className={classes.main}>
      <Container>
        <Box
          className={classes.root}
          textAlign="center"
          color="#ffffff"
          paddingY={[2, 3, 5, 6]}
        >
          <Grid container spacing={3} alignItems="center">
            {content.counters?.map((counter) => (
              <Grid
                key={counter.label}
                item
                xs={4}
                // style={{ borderRight: "1px solid #ebebeb" }}
                className={classes.grid}
              >
                <Typography
                  variant="h2"
                  component="label"
                  className={classes.counter}
                >
                  <CountUp
                    duration={5}
                    start={Number(counter.start)}
                    end={Number(counter.end)}
                  />
                  +
                </Typography>
                <Typography
                  variant="h3"
                  component="p"
                  className={classes.label}
                >
                  {counter.label}
                </Typography>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
    </div>
  );
};

export default Counters;
