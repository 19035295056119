import React, { useMemo } from "react";
import { Box, Container, Typography } from "@material-ui/core";
import PopularCourseCard from "../cards/PopularCourseCard";
import { Swiper, SwiperSlide } from "swiper/react";
import { SwiperOptions, Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/grid";
import PaginationAndNavigation from "components/sliders/PaginationAndNavigation";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    slider: {
      marginBottom: theme.spacing(3),
    },
  })
);

const PopularCourseSlider: React.FC<PopularCourseSliderProps> = ({
  content,
}) => {
  const classes = useStyles();
  const data = useMemo(() => {
    return {
      heading: content.heading,
      courses: content.courses,
    };
  }, [content]);

  const swiperSliderOptions: SwiperOptions = {
    modules: [Navigation, Pagination],
    cssMode: true,
    navigation: {
      nextEl: ".popul-course-button-next",
      prevEl: ".popul-course-button-prev",
    },
    pagination: {
      el: ".popul-course-pagination",
      clickable: true,
      type: "bullets",
    },
    breakpoints: {
      "600": {
        slidesPerView: 2,
        slidesPerGroup: 2,
        spaceBetween: 16,
      },
      "960": {
        slidesPerView: 3,
        slidesPerGroup: 3,
        spaceBetween: 32,
      },
      "1170": {
        slidesPerView: 3,
        slidesPerGroup: 3,
        spaceBetween: 48,
      },
    },
  };

  return (
    <Box paddingY={[2, 4, 6, 8]}>
      <Container>
        <Typography variant="h2" align="center">
          {data.heading}
        </Typography>
        <Box paddingY={6}>
          <Swiper {...swiperSliderOptions}>
            {data.courses.map((course) => (
              <SwiperSlide key={course.page_slug} className={classes.slider}>
                <PopularCourseCard
                  service_name={course.service_name}
                  course_count={course.course_count}
                  category_name={course.category_name}
                  course_learners={course.course_learners}
                  course_rating={course.course_rating}
                  page_slug={course.page_slug}
                  menu_icon_media_path={course.menu_icon_media_path}
                  category_slug_country={course.category_slug_country}
                  category_slug_generic={course.category_slug_generic}
                />
              </SwiperSlide>
            ))}
          </Swiper>
          <PaginationAndNavigation
            nextClassName="popul-course-button-next"
            prevClassName="popul-course-button-prev"
            paginationClassName="popul-course-pagination"
            paginationHideOnMobile={true}
            navigationAlignmentOnMobile="start"
            align="end"
          />
        </Box>
      </Container>
    </Box>
  );
};

export default PopularCourseSlider;
