import React from "react";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
  })
);

type BackDropLoaderProps = {
  open: boolean;
  onClose?: () => void;
};

export default function BackDropLoader({ open, onClose }: BackDropLoaderProps) {
  const classes = useStyles();

  return (
    <Backdrop className={classes.backdrop} open={open} onClick={onClose}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}
