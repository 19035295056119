import React, { useMemo } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { Divider } from "@material-ui/core";
import ButtonLink from "components/custom/ButtonLink";
import { useRouter } from "next/router";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      transition: "all .2s",
      height: "100%",
      [theme.breakpoints.up("md")]: {
        "&:hover": {
          transform: "scale(1.1)",
          boxShadow: "0px 8px 16px 0px rgb(0 0 0 / 15%)",
          "& .MuiCardActions-root": {
            display: "flex",
          },
        },
      },
    },
    cardContent: {
      minHeight: 222,
    },
    label: {
      background: `linear-gradient(to right, #6AD2FC , #599BFC)`,
      color: theme.palette.common.white,
      borderRadius: 50,
      padding: theme.spacing(0.25, 2),
      fontSize: 12,
      marginBottom: theme.spacing(2),
      width: "fit-content",
    },
    lists: {
      margin: 0,
      padding: 0,
      paddingLeft: theme.spacing(2.125),
      marginTop: theme.spacing(2),
      listStyle: "none",
      "& li": {
        "&::before": {
          content: '"\\2022"',
          fontWeight: "bold",
          display: "inline-block",
          width: "1em",
          marginLeft: "-1em",
          lineHeight: "25px",
          color: theme.palette.primary.main,
        },
      },
    },
    actions: {
      justifyContent: "center",
      paddingBottom: theme.spacing(3),
    },
  })
);
const PopularCategoryCardComponent: React.FC<PopularCategoryCardProps> = (
  props
) => {
  const classes = useStyles();

  const data = useMemo(() => {
    return {
      label: props.label,
      name: props.category_name,
      course_count: props.course_count,
      slug: props.page_slug,
      courses: props.popular_courses.map((item) => item.service_name),
    };
  }, [props]);

  const { country } = useRouter().query;

  return (
    <Card className={classes.root} variant="outlined">
      <CardContent className={classes.cardContent}>
        <Typography component="div" className={classes.label} gutterBottom>
          {data.label}
        </Typography>
        <Typography variant="h4" component="h3" gutterBottom>
          {data.name}
        </Typography>
        <Typography color="textSecondary" gutterBottom>
          {data.course_count} Courses
        </Typography>

        <Divider />
        <ul className={classes.lists}>
          {data.courses.slice(0, 3).map((cours, index) => (
            <li key={cours.toLocaleLowerCase() + index}>{cours}</li>
          ))}
        </ul>
      </CardContent>
      <CardActions className={classes.actions}>
        <ButtonLink
          href={
            country
              ? `/${country}/courses/${data.slug}`
              : `/courses/${data.slug}`
          }
          size="large"
          color="primary"
          variant="contained"
        >
          Explore Courses
        </ButtonLink>
      </CardActions>
    </Card>
  );
};
const PopularCategoryCard = React.memo(PopularCategoryCardComponent);
export default PopularCategoryCard;
