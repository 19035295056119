export const RED = "#F28D6F";
export const GREEN = "#67E4B3";
export const BLUE = "#70A9F8";
export const ORANGE = "#F8C270";
export const PINK = "#F3A0A0";

export const GRADIENT_LIGHT_TO_DARK_ONE = "#6AD2FC";
export const GRADIENT_LIGHT_TO_DARK_TWO = "#599BFC";
export const GRADIENT_DARK_TO_LIGHT_ONE = "#2563BE";
export const GRADIENT_DARK_TO_LIGHT_TWO = "#04B0F3";
