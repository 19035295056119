import React from "react";
import {
  Box,
  Container,
  Grid,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import PopularCategoryCard from "../cards/PopularCategoryCard";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.background.paper,
    },
  })
);
const PopularCategories: React.FC<PopularCategoriesProps> = ({ content }) => {
  const classes = useStyles();
  const sm = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const xs = useMediaQuery((theme: Theme) => theme.breakpoints.down("xs"));
  return (
    <Box className={classes.root} paddingY={[2, 4, 5, 6]}>
      <Container>
        <Typography variant="h2" align="center" gutterBottom>
          {content.heading}
        </Typography>
        <Box paddingTop={[2, 3, 4, 5]}>
          <Grid container spacing={xs ? 2 : sm ? 3 : 5}>
            {content.categories?.map((category, index) => (
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                lg={4}
                key={category.page_slug + index}
              >
                <PopularCategoryCard
                  label="Trending"
                  category_name={category.category_name}
                  page_slug={category.page_slug}
                  course_count={category.course_count}
                  popular_courses={category.popular_courses}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default PopularCategories;
