import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert, { AlertProps as CoreAlertProps } from "@material-ui/lab/Alert";

function CoreAlert(props: CoreAlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

type AlertProps = {
  open: boolean;
  onClose?: () => void;
  type: "success" | "warning" | "info" | "error";
  message?: string;
};
const Alert: React.FC<AlertProps> = ({
  open,
  onClose,
  type,
  children,
  message,
}) => {
  return (
    <Snackbar open={open} autoHideDuration={6000} onClose={onClose}>
      <CoreAlert onClose={onClose} severity={type}>
        {message || children}
      </CoreAlert>
    </Snackbar>
  );
};

export default Alert;
