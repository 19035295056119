import dynamic from "next/dynamic";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Typography,
} from "@material-ui/core";
import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import renderHTML from "lib/render-html";
import { countryToFlag } from "lib/helpers";
import { useRouter } from "next/router";
import isArray from "lodash/isArray";

const HomeBannerSvg = dynamic(() => import("../svg/HomeBannerSvg"));
const FormDialog = dynamic(() => import("../custom/FormDialog"));
const DownloadBrochureForm = dynamic(
  () => import("../forms/DownloadBrochureForm"),
  {
    // eslint-disable-next-line react/display-name
    loading: () => <CircularProgress size={27} />,
  }
);

const useHomeStyle = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },
    heading: {
      "& span": {
        color: theme.palette.primary.dark,
      },
    },
    heroImage: {
      [theme.breakpoints.down("xs")]: {
        display: "none",
      },
    },
    conuntryFlag: {
      display: "inline-block",
      borderRadius: "50%",
      position: "relative",
      backgroundColor: theme.palette.primary.main,
      marginLeft: theme.spacing(2),
      "&::before": {
        content: '""',
        position: "absolute",
        width: " 100%",
        height: "100%",
        backgroundColor: "inherit",
        borderRadius: "50%",
        zIndex: "-1",
        animation: `$ripple 1.5s ease-out infinite`,
      },
    },
    "@keyframes ripple": {
      from: {
        opacity: 1,
        transform: "scale(0)",
      },
      to: {
        opacity: 0,
        transform: "scale(2)",
      },
    },
    buttonFlag: {
      display: "flex",
      alignItems: "center",
    },
  })
);

const HomeBanner: React.FC<HomeBannerProps> = ({ content }) => {
  const classes = useHomeStyle();
  const [open, setOpen] = React.useState(false);
  const { country } = useRouter().query;
  const togglePopupForm = () => {
    setOpen((prev) => !prev);
  };
  return (
    <Box
      paddingY={[2, 3, 5, 8]}
      paddingBottom={[4, 6, 8, 10]}
      component="section"
    >
      <Container>
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Typography variant="h1" gutterBottom className={classes.heading}>
              {renderHTML(content.heading)}
            </Typography>
            <Typography gutterBottom className="paragraph" paragraph>
              {content.text}
            </Typography>
            <Box mt={2} className={classes.buttonFlag}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={togglePopupForm}
                className={classes.button}
              >
                Download Brochure
              </Button>
              {country && (
                <span className={classes.conuntryFlag}>
                  <Box
                    textAlign="center"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    fontSize="large"
                    width={30}
                    height={30}
                    bgcolor="white"
                    borderRadius="50%"
                    margin="2px"
                  >
                    {countryToFlag(
                      isArray(country) ? country[0] : country || "us"
                    )}
                  </Box>
                </span>
              )}
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} className={classes.heroImage}>
            <HomeBannerSvg />
          </Grid>
        </Grid>
        <FormDialog
          title="Download Brochure"
          open={open}
          onClose={togglePopupForm}
        >
          <DownloadBrochureForm />
        </FormDialog>
      </Container>
    </Box>
  );
};

export default HomeBanner;
