import { forwardRef } from "react";
import Button, { ButtonProps } from "@material-ui/core/Button";
import Link, { LinkProps } from "next/link";

type ButtonLinkProps = Omit<ButtonProps, "href" | "classes"> &
  Pick<LinkProps, "href" | "as" | "prefetch">;
// eslint-disable-next-line react/display-name
const ButtonLink = forwardRef<ButtonLinkProps, any>(
  ({ href, as, prefetch, ...props }, ref) => {
    return (
      <Link href={href} as={as} prefetch={prefetch} passHref>
        <Button ref={ref} {...props} />
      </Link>
    );
  }
);
export default ButtonLink;
