import { gql } from "@apollo/client";

const HOME_PAGE_QUERY = gql`
  query getPageContentBySlugType(
    $slug: String!
    $pageType: String!
    $countryCode: String!
    $popularCourseType: String!
    $popularCategoryType: String!
  ) {
    countries {
      name
      short_name
    }
    page_by_type_slug(
      page_slug: $slug
      page_type: $pageType
      country_code: $countryCode
    ) {
      title
      meta_description
      meta_keyword
      pageTypeConstantValue {
        name
      }
      pageContents {
        template {
          name
        }
        pagecontents(country_code: $countryCode)
      }
    }
    popularCourses(page_type: $popularCourseType)
    popularCategories(page_type: $popularCategoryType)
  }
`;

export default HOME_PAGE_QUERY;
