import React, { useMemo } from "react";
import { Box, Container, Typography } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { useRouter } from "next/router";

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    linksBox: {
      "& a": {
        textDecoration: "none",
        transition: "color .5s",
        color: theme.palette.grey[600],
        "&:not(:last-child)": {
          marginRight: theme.spacing(2),
        },
        "&:hover": {
          color: theme.palette.primary.main,
        },
        "&::after": {
          content: '""',
          display: "inline-block",
          backgroundColor: theme.palette.grey[300],
          position: "relative",
          width: 10,
          height: 10,
          borderRadius: 50,
          top: 1,
          right: theme.spacing(-1),
        },
        "&:last-child::after": {
          display: "none",
        },
      },
    },
  })
);

const TrendingCourses: React.FC<TrendingCoursesProps> = ({ content }) => {
  const classes = useStyle();
  const { label, urls } = content;
  const { country } = useRouter().query;

  const courseUrls = useMemo(() => {
    const checkLinkSlash = (linkStr: string): string => {
      if (linkStr.startsWith("/")) return `/${country}${linkStr}`;
      return `/${country}/${linkStr}`;
    };
    if (urls.length < 1) return [];
    return urls.map((item) => ({
      name: item.name,
      link_title: item.link_title,
      link: checkLinkSlash(item.link),
    }));
  }, [urls, country]);

  return (
    <Box paddingY={[2, 3, 4, 5]} component="section">
      <Container>
        <Typography variant="h5" component="h3">
          {label}
        </Typography>

        <Box paddingTop={2} className={classes.linksBox}>
          {courseUrls?.map((course, index) => (
            <a
              href={course.link}
              title={course.link_title}
              key={`tr-courses-${index}`}
            >
              {course.name}
            </a>
          ))}
        </Box>
      </Container>
    </Box>
  );
};

export default TrendingCourses;
