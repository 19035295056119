import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Box, Container, Grid, Typography, Hidden } from "@material-ui/core";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import NextArrow from "./NextArrow";
import PrevArrow from "./PrevArrow";
import TrainingDeliveryModeCard from "../cards/TrainingDeliveryModeCard";
import SliderNavigation from "./SliderNavigation";
import useSliderNavigation from "../hooks/useSliderNavigation";
import renderHTML from "lib/render-html";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.background.default,
    },
    heading: {
      "& span": {
        fontWeight: 500,
        marginTop: theme.spacing(2),
        display: "block",
      },
    },
    slider: {
      "& .slick-arrow": {
        top: "unset",
        bottom: theme.spacing(0),
      },
      "& .slick-prev": {
        left: "unset",
        right: -30,
        bottom: 155,
      },
      "& .slick-next": {
        right: -30,
      },
    },
    dots: {
      listStyle: "none",
      display: "flex!important",
      margin: 0,

      padding: 0,
      position: "absolute",
      right: -16,
      bottom: 40,
      flexDirection: "column",

      "& li": {
        "& div": {
          cursor: "pointer",
          transition: "all .5s",
          width: 7,
          height: 20,
          borderRadius: 50,
          border: `1px solid ${theme.palette.primary.main}`,
          marginTop: 5,
        },
      },
      "& li.slick-active": {
        "& div": {
          height: 35,
          backgroundColor: theme.palette.primary.main,
        },
      },
    },
    sliderGrid: {
      paddingTop: 0,
      paddingBottom: 0,
      marginBottom: theme.spacing(-2),
      marginTop: theme.spacing(-2),
      [theme.breakpoints.down("sm")]: {
        marginBottom: 0,
        marginTop: 0,
      },
    },
  })
);

const TrainingDeliveryModeSliderComponent: React.FC<
  TrainingDeliveryModeSliderProps
> = ({ content }) => {
  const classes = useStyles();
  const { sliderRef, next, previous } = useSliderNavigation();
  const settings = {
    className: classes.slider,
    dotsClass: classes.dots,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    swipeToSlide: true,
    dots: true,
    // eslint-disable-next-line react/display-name
    customPaging: function (i) {
      return <div key={i + 1}></div>;
    },
    nextArrow: <NextArrow down={true} />,
    prevArrow: <PrevArrow up={true} />,
    responsive: [
      {
        breakpoint: 1170,
        settings: {
          dots: true,
        },
      },
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          dots: false,
          arrows: false,
          vertical: false,
          verticalSwiping: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          dots: false,
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          vertical: false,
          verticalSwiping: false,
        },
      },
    ],
  };
  return (
    <Box
      className={classes.root}
      // paddingY={[2, 4, 6, 8]}
    >
      <Container>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Typography
              variant="h1"
              component="h3"
              gutterBottom
              className={classes.heading}
            >
              {renderHTML(content.heading)}
            </Typography>

            <Typography paragraph>{content.sub_heading}</Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            className={classes.sliderGrid}
          >
            <Box pr={[0, 2, 3, 4]}>
              <Slider {...settings} ref={sliderRef}>
                {content.training_mods
                  ?.filter((x) => x.mode !== null || x.text !== null)
                  .map((mode, index) => (
                    <TrainingDeliveryModeCard
                      key={`training-mode-` + index}
                      image_url={
                        process.env.NEXT_PUBLIC_ADMIN_URL +
                        "storage/images/" +
                        mode.image_url
                      }
                      mode={mode.mode}
                      text={mode.text}
                    />
                  ))}
              </Slider>
              <Hidden mdUp>
                <SliderNavigation onNext={next} onPrevious={previous} />
              </Hidden>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
const TrainingDeliveryModeSlider = React.memo(
  TrainingDeliveryModeSliderComponent
);
export default TrainingDeliveryModeSlider;
